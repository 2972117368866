import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { useDevicesHostsDataContext } from "../../context/devicesHostsDataContext";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import filterStyles from "./filterStyles";
import { formatMacAddress } from "../../utils/mac_formatter";

export const defaultHostsFilterState = {
  ip_address: "",
  mac: "",
  vendor: "",
  device_type: "",
  model: "",
  switch_port: "",
  switch_name: "",
};

export default function BuildingDevicesFilterMenu(props: any) {
  const { filterState, setFilterState } = props;
  const { allHosts, setHosts } = useDevicesHostsDataContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const scrollToFilter = () => {
    const element = document.getElementById("hosts") as HTMLElement | null;
    if (element) {
      element.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  };

  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
    scrollToFilter();
    setAnchorEl(event.currentTarget);
    if (open) {
      setAnchorEl(null);
    }
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const filter = () => {
    return allHosts.filter((obj) => {
      const matchesFilter = (key: string | number) => {
        const filterValue = filterState[key];
        // @ts-ignore
        if (filterValue === "" || obj[key] == null) {
          return filterValue === "";
        }

        if (key === "mac") {
          return formatMacAddress(obj[key])
            .toLowerCase()
            .includes(formatMacAddress(filterValue).toLowerCase());
        } else {
          // @ts-ignore
          return obj[key].toLowerCase().includes(filterValue.toLowerCase());
        }
      };

      return (
        matchesFilter("ip_address") &&
        matchesFilter("mac") &&
        matchesFilter("vendor") &&
        matchesFilter("device_type") &&
        matchesFilter("model") &&
        matchesFilter("switch_port") &&
        matchesFilter("switch_name")
      );
    });
  };

  const handleFilterInput = (filterName: string) => (e: any) => {
    e.preventDefault();
    const searchValue: string = e.target.value.toLowerCase();

    let tempFilterState = filterState;
    tempFilterState[filterName] = searchValue;
    setFilterState(tempFilterState);

    const filteredArray = filter();
    setHosts(filteredArray);
  };

  const handleClearFilter = (filterName: string) => (e: any) => {
    let tempFilterState = filterState;
    tempFilterState[filterName] = "";
    setFilterState(tempFilterState);

    const filteredArray = filter();
    setHosts(filteredArray);
  };

  const handleClearAllFilters = (e: any) => {
    setHosts(allHosts);
    setFilterState({
      ip_address: "",
      mac: "",
      vendor: "",
      device_type: "",
      model: "",
      switch_port: "",
      switch_name: "",
    });
  };

  return (
    <React.Fragment>
      <Tooltip title="Apply filters">
        <IconButton
          onClick={handleClickFilter}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="filter-menu"
        open={open}
        onClose={handleCloseFilter}
        slotProps={{
          paper: {
            sx: { ...filterStyles.menu },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={{ display: "flex" }}>
          <Button
            sx={{ ...filterStyles.actionButton }}
            variant="outlined"
            onClick={handleClearAllFilters}
          >
            <FilterListOffOutlinedIcon fontSize={"small"} /> Clear
          </Button>
          <Button
            sx={{ ...filterStyles.actionButton }}
            variant="outlined"
            onClick={handleCloseFilter}
          >
            Close
          </Button>
        </MenuItem>
        <Divider />
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["ip_address"]
                ? "IP address:" + filterState["ip_address"]
                : "IP address"
            }
            value={filterState["ip_address"] ? filterState["ip_address"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("ip_address")}
          />
          <IconButton onClick={handleClearFilter("ip_address")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["mac"]
                ? "MAC address:" + filterState["mac"]
                : "MAC address"
            }
            value={filterState["mac"] ? filterState["mac"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("mac")}
          />
          <IconButton onClick={handleClearFilter("mac")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["vendor"]
                ? "Vendor:" + filterState["vendor"]
                : "Vendor"
            }
            value={filterState["vendor"] ? filterState["vendor"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("vendor")}
          />
          <IconButton onClick={handleClearFilter("vendor")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["device_type"]
                ? "Device type:" + filterState["device_type"]
                : "Device type"
            }
            value={filterState["device_type"] ? filterState["device_type"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("device_type")}
          />
          <IconButton onClick={handleClearFilter("device_type")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["model"] ? "Model:" + filterState["model"] : "Model"
            }
            value={filterState["model"] ? filterState["model"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("model")}
          />
          <IconButton onClick={handleClearFilter("model")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["switch_port"]
                ? "Switch port:" + filterState["switch_port"]
                : "Switch port"
            }
            value={filterState["switch_port"] ? filterState["switch_port"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("switch_port")}
          />
          <IconButton onClick={handleClearFilter("switch_port")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["switch_name"]
                ? "Switch name:" + filterState["switch_name"]
                : "Switch name"
            }
            value={filterState["switch_name"] ? filterState["switch_name"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("switch_name")}
          />
          <IconButton onClick={handleClearFilter("switch_name")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
