import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import { BuildingDevicesHostDataType } from "../../types/buildingTypes";
import DevicesHostsSearch from "./DevicesHostsSearch";
import TablePlaceholder from "../placeholders/TablePlaceholder";
import BuildingDevicesFilterMenu, {
  defaultHostsFilterState,
} from "../filters/BuildingDevicesFilterMenu";
import DeviceDataGettingError from "../placeholders/DeviceDataGettingError";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadHostsCell {
  disablePadding: boolean;
  id: keyof BuildingDevicesHostDataType;
  label: string;
  numeric: boolean;
}

const headHostsCells: readonly HeadHostsCell[] = [
  {
    id: "ip_address",
    numeric: false,
    disablePadding: false,
    label: "IP address",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "mac",
    numeric: false,
    disablePadding: false,
    label: "MAC address",
  },
  {
    id: "vendor",
    numeric: false,
    disablePadding: false,
    label: "Vendor",
  },
  {
    id: "device_type",
    numeric: false,
    disablePadding: false,
    label: "Device type",
  },
  {
    id: "model",
    numeric: false,
    disablePadding: false,
    label: "Model",
  },
  {
    id: "serial",
    numeric: false,
    disablePadding: false,
    label: "Serial",
  },
  {
    id: "switch_ip_address",
    numeric: false,
    disablePadding: false,
    label: "Switch ip address",
  },
  {
    id: "switch_port",
    numeric: false,
    disablePadding: false,
    label: "Switch port",
  },
  {
    id: "switch_name",
    numeric: false,
    disablePadding: false,
    label: "Switch name",
  },
  {
    id: "last_seen",
    numeric: false,
    disablePadding: false,
    label: "Last seen",
  },
];

interface HostsTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof BuildingDevicesHostDataType,
  ) => void;
  order: Order;
  orderBy: string;
}

function HostsTableHead(props: HostsTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof BuildingDevicesHostDataType) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headHostsCells.map((HeadHostsCell) => (
          <TableCell
            key={HeadHostsCell.id}
            align={HeadHostsCell.numeric ? "right" : "left"}
            padding={HeadHostsCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === HeadHostsCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === HeadHostsCell.id}
              direction={orderBy === HeadHostsCell.id ? order : "asc"}
              onClick={createSortHandler(HeadHostsCell.id)}
            >
              {HeadHostsCell.label}
              {orderBy === HeadHostsCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface TableToolbarProps {
  title: string;
  search: boolean;
}

function TableToolbar(props: TableToolbarProps) {
  const { title, search } = props;
  const [filterInput, setFilterInput] = React.useState(defaultHostsFilterState);

  return (
    <Toolbar>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        {title}:
      </Typography>
      {search && <DevicesHostsSearch />}
      <BuildingDevicesFilterMenu
        filterState={filterInput}
        setFilterState={setFilterInput}
      />
    </Toolbar>
  );
}

interface BuildingDevicesHostsTableProps {
  hosts: BuildingDevicesHostDataType[] | [];
  isLoading: boolean;
  apiErrorFlag: boolean;
}

export default function BuildingDevicesHostTable(
  props: BuildingDevicesHostsTableProps,
) {
  const { hosts, isLoading, apiErrorFlag } = props;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof BuildingDevicesHostDataType>("ip_address");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof BuildingDevicesHostDataType,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - hosts.length) : 0;

  const hostRows = React.useMemo(
    () =>
      stableSort(hosts, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, hosts],
  );

  const host_column_labels = headHostsCells.map((item) => item.label);

  return (
    <Box sx={{ width: "100%", margin: "5pt" }} id={"hosts"}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar title={"Hosts"} search={true} />
        {isLoading ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <TablePlaceholder rows={10} columns={host_column_labels} />
          </Box>
        ) : (
          <TableContainer sx={{ minHeight: "30rem" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <HostsTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {hostRows.map((row, index) => {
                  const labelId = `hosts-table-checkbox-${index}`;

                  return (
                    <TableRow hover sx={{ cursor: "pointer" }}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        {row.ip_address}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.mac}</TableCell>
                      <TableCell>{row.vendor}</TableCell>
                      <TableCell>{row.device_type}</TableCell>
                      <TableCell>{row.model}</TableCell>
                      <TableCell>{row.serial}</TableCell>
                      <TableCell>{row.switch_ip_address}</TableCell>
                      <TableCell>{row.switch_port}</TableCell>
                      <TableCell>{row.switch_name}</TableCell>
                      <TableCell>
                        {new Date(row.last_seen).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                {apiErrorFlag && (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <DeviceDataGettingError entity={"hosts"} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[
            10,
            20,
            30,
            { value: hosts.length, label: "All" },
          ]}
          component="div"
          count={hosts.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Rows per table:"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
