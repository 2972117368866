import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import Link from '@mui/material/Link';
import s from './DeviceProcessingError.module.css'
import config from '../../config'


export default function DeviceProcessingError() {
  const spaceUrl: string = config.gChatSupportSpace;

  return (
    <Box className={s.processing_error_block}>
      <ReportGmailerrorredIcon fontSize="large" className={s.processing_error_icon} />
      <Typography id={s.processing_error_text}>
        Sorry, an error occurred during data processing.<br />If this information is important to you, please get in touch
        with <Link href={spaceUrl} target="_blank">#ncc-support</Link> in gChat for further
        assistance.
      </Typography>
    </Box>
  );
}
