import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

const filterStyles: {
  menu: SxProps<Theme>;
  actionButton: SxProps<Theme>;
  textField: SxProps<Theme>;
} = {
  menu: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.30))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  actionButton: {
    margin: "auto",
    height: "30pt",
  },
  textField: {
    width: "20ch",
    margin: "5pt",
    height: "30pt",
  },
};

export default filterStyles;
